/** @jsx jsx */
import { jsx, Box } from "theme-ui";

import App from "next/app";
import Head from "next/head";

import * as Sentry from "@sentry/browser";
import Router from "next/router";

import { ThemeProvider } from "theme-ui";
import theme from "data/theme";
import analytics from "utils/analytics";
import meta from "data/meta";

// fire ga event whenever changing pages
Router.events.on("routeChangeComplete", (url) => analytics.pageview(url));

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.NODE_ENV,
});
export default class MyApp extends App {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    super.componentDidCatch(error, errorInfo);
  }
  render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <Head>
          <title>Neue Goods</title>
          <link rel="icon" href="/favicon.ico" />
          <link rel="stylesheet" href="/css/global.css" />
          <link rel="stylesheet" href="/css/fonts.css" />
          <link rel="stylesheet" href="/css/widget.css" />

          <meta name="description" content={meta.description} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          <meta property="og:title" content={meta.title} />
          <meta property="og:url" content={meta.url} />
          <meta property="og:description" content={meta.description} />
          <meta
            property="og:image"
            content="https://www.neuegoods.com/opengraph.jpg"
          />
          <meta property="og:type" content="website" />

          <meta name="twitter:title" content={meta.title} />
          <meta name="twitter:url" content={meta.url} />
          <meta name="twitter:card" content={meta.description} />
          <meta
            name="twitter:image"
            content="https://www.neuegoods.com/opengraph.jpg"
          />

          <meta name="msapplication-TileColor" content="#000000" />
          <meta name="theme-color" content="#ffffff" />
          <link rel="manifest" href="/site.webmanifest" />
        </Head>
        <ThemeProvider theme={theme}>
          <Box variant="boxes.global">
            <Component {...pageProps} />
          </Box>
        </ThemeProvider>
      </>
    );
  }
}
