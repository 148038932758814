const breakpoints = ["40em", "52em", "64em", "72em"];

const fontStack = `"Formular Mono", "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace`;

const fonts = {
  heading: fontStack,
  body: fontStack,
  sans:
    '"Formular", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
};

const fontSizes = {
  xxxxl: 96,
  xxxl: 76,
  xxl: 66,
  xl: 56,
  l: 46,
  m: 36,
  s: 26,
  xs: 18,
  body: 16,
  sub: 12,
};

const fontWeights = {
  heading: 400,
};

const lineHeights = {
  heading: 1.4,
  body: 1.5,
};

const colors = {
  black: {
    100: "#000000",
    90: "#1A1A1A",
    80: "#333333",
    70: "#4D4D4D",
    60: "#666666",
    50: "#7F7F7F",
    40: "#999999",
    30: "#B3B3B3",
    20: "#CCCCCC",
    10: "#E6E6E6",
  },
  white: {
    100: "#FFFFFF",
  },
  green: "#5AFF20",
  red: "#FF3030",
  transparent: "transparent",
  blue: "#4844FF",
};

const space = {
  xxxxs: 0,
  xxs: 4,
  xs: 8,
  s: 16,
  m: 24,
  l: 32,
  xl: 48,
  xxl: 64,
  xxxl: 96,
  xxxxl: 128,
};

export const radii = {
  m: 10,
  xl: 20,
};

export const shadows = {
  button: {
    primary: {
      default: `0 0 0 1px ${colors.black[70]}`,
    },
  },
};

export const easing = {
  bouncy: {
    string: "cubic-bezier(0.68, -0.55, 0.265, 1.55)",
    array: [0.68, -0.55, 0.265, 1.55],
  },
  smooth: {
    string: "cubic-bezier(0.23, 1, 0.32, 1)",
    array: [0.23, 1, 0.32, 1],
  },
};

export const transitions = {
  smooth: {
    fast: `all 300ms ${easing.smooth.string}`,
    medium: `all 600ms ${easing.smooth.string}`,
    slow: `all 900ms ${easing.smooth.string}`,
  },
};

export const styles = {
  root: {
    bg: "black.100",
  },
};

const boxes = {
  global: {
    color: "white.100",
    fontFamily: "body",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    textTransform: "uppercase",
    letterSpacing: "0.083333333em",
    lineHeight: 1.8,
    fontSize: "sub",
    fontWeight: 400,
  },
  column: {
    flexDirection: "column",
  },
  notice: {
    maxWidth: 200,
    mx: "auto",
    textAlign: "center",
    fontSize: "body",
  },
};

const buttons = {
  primary: {
    outline: "none",
    bg: "transparent",
    border: "solid 1px",
    borderColor: "black.80",
    borderRadius: 0,
    color: "white.100",
    fontFamily: "body",
    fontSize: "body",
    textTransform: "uppercase",
    transition: transitions.smooth.fast,
    cursor: "pointer",
    "@media (hover: hover)": {
      "&:hover": {
        bg: "white.100",
        color: "black.100",
      },
      ":disabled:hover": {
        bg: "transparent",
        color: "white.100",
      },
    },

    ":disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
      textDecoration: "line-through",
    },
  },
  flat: {
    outline: "none",
    bg: "black.90",
    color: "white.100",
    fontWeight: 400,
    transition: transitions.smooth.fast,
    "@media (hover: hover)": {
      "&:hover": {
        bg: "black.80",
      },
    },
  },
  blank: {
    variant: "forms.input",
    bg: "transparent",
    color: "black.70",
    textAlign: "center",
    cursor: "pointer",
  },
};

const text = {
  banner: {
    fontSize: ["l", "xl", "xxl", "xxxxl"],
    lineHeight: 1,
  },
  mono: {
    xs: {
      color: "white.100",
      fontFamily: "mono",
      fontSize: "xs",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    m: {
      color: "white.100",
      fontFamily: "mono",
      fontSize: "m",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    l: {
      color: "white.100",
      fontFamily: "mono",
      fontSize: "l",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    body: {
      color: "black.70",
      fontFamily: "mono",
      fontSize: "body",
      fontWeight: 400,
      lineHeight: 1.4,
    },
  },
  stnd: {
    xs: {
      color: "white.100",
      fontFamily: "body",
      fontSize: "xs",
      fontWeight: 400,
      lineHeight: 1.4,
    },
    s: {
      color: "white.100",
      fontFamily: "body",
      fontSize: "s",
      fontWeight: 400,
      lineHeight: 1.4,
    },
    m: {
      color: "white.100",
      fontFamily: "body",
      fontSize: "m",
      fontWeight: 400,
      lineHeight: 1.4,
    },
    body: {
      fontSize: "body",
      fontWeight: 400,
    },
  },
};

const forms = {
  input: {
    fontFamily: "body",
    fontSize: "body",
    border: "none",
    textTransform: "uppercase",
    letterSpacing: 1,
    outline: "none",
    bg: "transparent",

    color: "white.100",
    "&::placeholder": {
      color: "black.60",
    },
    "&:-internal-autofill-selected": {
      backgroundColor: "transparent",
      color: "white.100",
    },
  },
};

const links = {
  default: {
    textDecoration: "none",
    color: "blue",
  },
  social: {
    fontSize: "sub",
    color: "#fff",
    textDecoration: "none",
    color: "black.50",
    transition: transitions.smooth.fast,
    "@media (hover: hover)": {
      "&:hover": {
        color: "white.100",
      },
    },
  },
};

// Construct the theme
const theme = {
  breakpoints,
  boxes,
  buttons,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  shadows,
  space,
  styles,
  text,
  transitions,
  forms,
  links,
};

export default theme;
